// import cookieconsent from 'cookieconsent';

// function enablecookies() {
//   dataLayer.push({ event: 'cookieOptIn' });
// }

// function disablecookies() {
//   // disabled cookies
// }

// function revokeCookies() {
//   // revoked cookies
// }

// $(document).ready(function(){
//   window.cookieconsent.initialise({
//     window: '<div role="dialog" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window {{classes}}">'
//     + '<div class="container"><div class="row align-items-center">{{children}}</div></div></div>',
//     elements: {
//       messagelink: '<div class="col-12"><span id="cookieconsent:desc" class="cc-message">{{message}} '
//       + '<a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{href}}" target="_blank">{{link}}</a></span></div>',
//       allow: '<div class="col-12"><a href="#" class="btn btn-success cc-btn cc-allow col-12">{{allow}}</a></div>',
//     },
//     theme: 'classic',
//     type: 'opt-in',
//     revokable: true,
//     cookie: {
//       expiryDays: 14,
//     },
//     content: {
//       message: 'Wir verwenden Cookies. Um Dir einen uneingeschränkten Service zu gewährleisten, stimme der Cookie-Nutzung zu. Mehr dazu in unserer ',
//       dismiss: 'Got it!',
//       allow: 'Ja, einverstanden',
//       deny: '&#215;',
//       link: 'Datenschutzerklärung.',
//       href: '/datenschutz',
//     },
//     onInitialise() {
//       const { type } = this.options;
//       const didConsent = this.hasConsented();
//       if (type === 'opt-in' && didConsent) {
//         enablecookies();
//       }
//       if (type === 'opt-in' && !didConsent) {
//         disablecookies();
//       }
//     },
//     onStatusChange() {
//       const { type } = this.options;
//       const didConsent = this.hasConsented();
//       if (type === 'opt-in' && didConsent) {
//         enablecookies();
//       }
//       if (type === 'opt-in' && !didConsent) {
//         disablecookies();
//       }
//     },
//     onRevokeChoice: function() {
//       const { type } = this.options;
//       const didConsent = this.hasConsented();
//       if (type === 'opt-in' && didConsent) {
//         enablecookies();
//       }
//       if (type === 'opt-in' && !didConsent) {
//         revokeCookies();
//       }
//     }
//   });

//   $('#body-datenschutz a[href="#cookieentscheidungzuruecknehmen"]').click(function(){
//     $('.cc-revoke').click();
//     $(this).after(`
//       <div class="alert alert-success" role="alert">
//         Die Entscheidung wurde erfolgreich zurückgesetzt.
//       </div>
//     `);
//   });
// });
